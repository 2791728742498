import React, { useState, useEffect, useContext } from 'react'
import ReactPlayer from 'react-player'
import styled from 'styled-components'
// import WBTCard from '../components/WBTCard'
import { firestore } from '../firebase'
// import { CircleLoader } from 'react-spinners'
// import { AuthContext } from '../auth/Auth'
import { useParams } from 'react-router-dom'
import { AuthContext } from '../auth/Auth'
import firebase from 'firebase/app'
import { LanguageContext } from '../App'

const StyledVideo = styled.div`
  /* background-color: #f0f0f0; */
  max-width: 1200px;
  margin: 0 auto;
  padding: 2em;
  height: calc(100vh - 61px - 4em);

  .intro {
    font-size: 1.1em;
    line-height: 1.5em;
  }

  .card-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 250px));
    gap: 2em;
    width: 100%;
    padding-top: 1em;
  }

  h2 {
    margin-top: 2em;
  }

  .player-area {
    position: relative;
    /* padding-bottom: 56.25%; */
    overflow: hidden;
    margin-bottom: 2em;

    .hls-player {
      height: 100%;
      max-width: 100%;
      width: 100%;
    }
  }
`

const Video = () => {
  const [videos, setVideos] = useState(null)
  const [videosDe, setVideosDe] = useState(null)
  const [videosEn, setVideosEn] = useState(null)
  const [title, setTitle] = useState(null)
  const [titleEn, setTitleEn] = useState(null)
  const [videosStarted, setVideosStarted] = useState({})
  const [videosFinished, setVideosFinished] = useState({})
  const { id } = useParams()
  const { currentUser: user } = useContext(AuthContext)
  const [language] = useContext(LanguageContext)

  useEffect(() => {
    const fetchCourses = async () => {
      const snapshot = await firestore
        .collection('coursesPhase2')
        .where('url', '==', `videos/${id}`)
        .orderBy('id')
        .get()
      const courseList = []
      snapshot.docs.forEach((doc) => courseList.push(doc.data()))
      const videoDeURL = courseList[0].videos
      const videoEnURL = courseList[0].videosEn
      const title = courseList[0].title
      const titleEn = courseList[0].titleEn
      setTitle(title)
      setTitleEn(titleEn)
      setVideosDe(videoDeURL)
      setVideosEn(videoEnURL)

      language === 'de' ? setVideos(videoDeURL) : setVideos(videoEnURL)
      // console.log(video)
    }
    fetchCourses()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [videos])

  useEffect(() => {
    language === 'de' ? setVideos(videosDe) : setVideos(videosEn)
  }, [language, videosDe, videosEn])

  const onVideoStart = (index) => {
    // console.log(index)
    if (videosStarted[index]) return
    firestore
      .collection('usersPhase2')
      .doc(user.uid)
      .update({
        videosStarted: firebase.firestore.FieldValue.arrayUnion(
          `${id}-${index + 1}`
        ),
      })
    setVideosStarted({ ...videosStarted, [index]: true })
  }

  const onVideoProgress = (index, { played }) => {
    if (videosFinished[index]) return
    if (played < 0.95) return
    firestore
      .collection('usersPhase2')
      .doc(user.uid)
      .update({
        videosFinished: firebase.firestore.FieldValue.arrayUnion(
          `${id}-${index + 1}`
        ),
      })
    setVideosFinished({ ...videosFinished, [index]: true })
  }

  return (
    <div>
      <StyledVideo>
        {videos && (
          <>
            <div style={{ maxWidth: '1280px' }}>
              <div
                style={{
                  position: 'relative',
                  // paddingBottom: '56.25%',
                  overflow: 'hidden',
                  marginBottom: '2em',
                }}>
                {videos.map((video, index) => (
                  <div key={index}>
                    <h2>
                      {language === 'de' ? title : titleEn}{' '}
                      {videos.length >= 2 && (
                        <span>
                          – {language === 'de' ? 'Teil' : 'Part'} {index + 1}
                        </span>
                      )}
                    </h2>
                    <ReactPlayer
                      url={video}
                      controls
                      width='100%'
                      height='100%'
                      onStart={function () {
                        onVideoStart(index)
                      }}
                      onProgress={function (state) {
                        onVideoProgress(index, state)
                      }}
                    />
                  </div>
                ))}
              </div>
            </div>
          </>
        )}
        {/* {id === 'webinar' && (
          <>
            <iframe
              title='webinar-poll'
              width='100%'
              height='1280px'
              src='https://forms.office.com/Pages/ResponsePage.aspx?id=r3uzornq40qxnLrUP2i1gIgNO-p5JhxNo0D-buLTV8pUMEI4R1RMMlBNU1pWRFJFVEpSNzA3TDFEWS4u&embed=true'
              frameBorder='0'
              marginWidth={0}
              marginHeight={0}
              style={{ border: 'none', maxWidth: '100%' }}
              allowFullScreen
              webkitallowfullscreen
              mozallowfullscreen
              msallowfullscreen></iframe>
            <iframe
              title='webinar-poll-results'
              width='100%'
              height='520px'
              src='https://forms.office.com/Pages/AnalysisPage.aspx?id=r3uzornq40qxnLrUP2i1gIgNO-p5JhxNo0D-buLTV8pUMEI4R1RMMlBNU1pWRFJFVEpSNzA3TDFEWS4u&AnalyzerToken=pz74UZe0fVP2snFC8Blf25HaOcYQxfIU'
              frameBorder='0'
              marginWidth={0}
              marginHeight={0}
              style={{ border: 'none', maxWidth: '100%', maxHeight: '100vh' }}
              allowFullScreen
              webkitallowfullscreen
              mozallowfullscreen
              msallowfullscreen></iframe>
          </>
        )} */}
      </StyledVideo>
    </div>
  )
}

export default Video
