import React, { useState, useCallback, useContext } from 'react'
import { auth, analytics } from '../firebase'
import { AuthContext } from '../auth/Auth'
import { Redirect, withRouter } from 'react-router-dom'
import styled from 'styled-components'
import { isIE, engineName, osName } from 'react-device-detect'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faChrome,
  faEdge,
  faSafari,
  faFirefoxBrowser,
} from '@fortawesome/free-brands-svg-icons'

// import './Login.scss'

const StyledLogin = styled.div`
  --text-color: #4b4b4b;

  background-color: #eaebed;
  color: var(--text-color);
  font-size: 1.25rem;
  background-image: url(/img/back-possehl.png);
  background-repeat: repeat;
  display: flex;
  justify-content: center;
  height: calc(100vh - 59px);
  height: calc(100vh - var(--nav-size) - 1px);

  main {
    margin-top: 1em;

    .logo {
      margin: 2em auto;
      display: block;
      width: 100px;
    }

    .title {
      text-align: center;

      em {
        color: #aaa;
        font-size: 0.8em;
      }
    }

    form {
      margin: 2em auto;
      max-width: 500px;

      background-color: white;
      padding: 1em;
      border-radius: 1em;
      box-shadow: 0 0 40px -10px rgba(0, 0, 0, 0.3);
    }

    .field {
      position: relative;
      display: flex;
      flex-direction: column;
      border-bottom: 2px dashed var(--text-color);
      margin: 4rem auto 1rem;
    }

    input {
      height: 1.5em;
      border: none;
      border-radius: 0.25em;
      padding: 0;
      outline: none;
      overflow: hidden;
      margin: 0;
      width: 100%;
      background: none;
      color: var(--text-color);
      font-weight: bold;
      font-size: 1rem;

      // &:valid {
      //   color: yellowgreen;
      // }

      // &:invalid {
      //   color: orangered;
      // }
    }

    .label {
      color: var(--text-color);
      font-size: 1.2rem;
    }

    // Animation
    .field::after {
      content: '';
      position: relative;
      display: block;
      height: 4px;
      width: 100%;
      background: var(--primary);
      transform: scaleX(0);
      transform-origin: 0%;
      transition: transform 500ms ease;
      top: 2px;
    }

    .field:focus-within {
      border-color: transparent;

      &::after {
        transform: scaleX(1);
      }
    }

    // IE fix
    .field.focus-within {
      border-color: transparent;

      &::after {
        transform: scaleX(1);
      }
    }

    .label {
      /* z-index: -1; */
      position: absolute;
      transform: translateY(0rem);
      transform-origin: 0%;
      transition: transform 400ms;
      pointer-events: none;
    }

    .field:focus-within .label,
    .input:not(:placeholder-shown) + .label {
      transform: scale(0.8) translateY(-2rem);
    }

    // IE fix
    .js-focus-within {
      .field.focus-within .label {
        transform: scale(0.8) translateY(-2rem);
      }
      :not(.placeholder-shown) + .label {
        transform: scale(0.8) translateY(-2rem);
      }
    }

    .primary {
      padding: 0.75rem;
      background-color: var(--primary);
      color: white;
      font-size: 1.2rem;

      &:hover {
        background-color: #0083da;
        color: white;
      }
    }

    h1 {
      color: var(--text-color);
    }

    .error {
      color: red;
      font-weight: bold;
      text-align: center;
      background-color: #ff000040;
      padding: 0.25em;
      border-radius: 0.25em;
      margin-top: 2em;
    }

    .submitButton {
      margin-top: 2em;
      width: 100%;
      border: none;
      cursor: pointer;
      border-radius: 0.25em;
      transition: background-color 0.2s;
    }
  }

  .browsersupport {
    margin-top: 3em;
    font-size: 1rem;
    line-height: 1.5rem;

    .container {
      max-width: 500px;
      background-color: #dfdfdf;
      border-radius: 0.5em;
      margin: 0 auto;
      box-shadow: 0 10px 50px 0 rgba(0, 0, 0, 0.2);
    }

    p {
      padding: 1em;
    }

    h2 {
      margin: 0;
      padding: 1rem;
      padding-bottom: 0;
      text-align: center;
    }

    .browsers {
      display: flex;
      justify-content: space-around;
    }

    .browser {
      padding: 1em;
      padding-top: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: #4b4b4b;
      transition: color 0.2s;

      &:hover {
        color: #006ab3;
      }
    }

    .browsertitle {
      margin-top: 1em;
      font-weight: bold;
    }
  }
`

const Login = ({ history }) => {
  const [error, setError] = useState(null)

  const handleLogin = useCallback(
    async (event) => {
      event.preventDefault()
      const { lms_username, lms_password } = event.target.elements
      const email = lms_username.value
      try {
        const user = await auth.signInWithEmailAndPassword(
          email,
          lms_password.value
        )
        if (user.additionalUserInfo.providerId) {
          const method = user.additionalUserInfo.providerId
          analytics.logEvent('login', { method })
        }
        history.push('/')
      } catch (error) {
        setError('Fehler beim Login')
        console.error(error)
      }
    },
    [history]
  )

  const { currentUser } = useContext(AuthContext)

  if (currentUser) {
    return <Redirect to='/' />
  }

  return (
    <>
      <StyledLogin>
        <main>
          <img src='/logo512.png' className='logo' alt='' />
          <h1 className='title'>
            Possehl Academy LMS
            {/* <br />
            <em>Testzugang</em> */}
          </h1>
          {isIE || engineName === 'EdgeHTML' ? (
            <div className='browsersupport'>
              <div className='container'>
                <h2>Bitte aktualisieren Sie Ihren Browser!</h2>
                <p>Wir empfehlen die Verwendung von Google Chrome.</p>
                <div className='browsers'>
                  {osName === 'Mac OS' && (
                    <a
                      href='https://www.apple.com/de/safari/'
                      target='_blank'
                      rel='noopener noreferrer'
                      className='browser'>
                      <FontAwesomeIcon icon={faSafari} size='4x' />
                      <span className='browsertitle'>Safari</span>
                    </a>
                  )}
                  <a
                    href='https://www.google.com/intl/de/chrome/'
                    target='_blank'
                    rel='noopener noreferrer'
                    className='browser'>
                    <FontAwesomeIcon icon={faChrome} size='4x' />
                    <span className='browsertitle'>Chrome</span>
                  </a>
                  <a
                    href='https://www.mozilla.org/de/firefox/new/'
                    target='_blank'
                    rel='noopener noreferrer'
                    className='browser'>
                    <FontAwesomeIcon icon={faFirefoxBrowser} size='4x' />
                    <span className='browsertitle'>Firefox</span>
                  </a>
                  <a
                    href='https://www.microsoft.com/de-de/edge'
                    target='_blank'
                    rel='noopener noreferrer'
                    className='browser'>
                    <FontAwesomeIcon icon={faEdge} size='4x' />
                    <span className='browsertitle'>Edge</span>
                  </a>
                </div>
              </div>
            </div>
          ) : (
            <form id='loginForm' onSubmit={handleLogin}>
              <div className='field'>
                <input
                  type='text'
                  name='lms_username'
                  className='input'
                  placeholder=' '
                />
                <label htmlFor='lms_username' className='label'>
                  Benutzername
                </label>
              </div>
              <div className='field'>
                <input
                  type='password'
                  name='lms_password'
                  className='input'
                  placeholder=' '
                />
                <label htmlFor='lms_password' className='label'>
                  Passwort
                </label>
              </div>
              {error ? <div className='error'>{error}</div> : null}
              <button type='submit' className='submitButton primary'>
                Einloggen
              </button>
            </form>
          )}
        </main>

        {/* <div
        className='modal fade'
        id='incorrectLogin'
        tabIndex={-1}
        role='dialog'
        aria-labelledby='incorrectLogin'
        aria-hidden='true'>
        <div className='modal-dialog'>
          <div className='modal-content'>
            <div className='modal-header bg-primary text-white'>
              <h5 className='modal-title' id='incorrectLogin'>
                Falscher Login
              </h5>
              <button
                type='button'
                className='close'
                data-dismiss='modal'
                aria-label='Close'>
                <span aria-hidden='true'>&times;</span>
              </button>
            </div>
            <div className='modal-body'>
              Ihre Logindaten waren falsch.
              <br />
              Bitte versuchen Sie es erneut.
              <br />
              <br />
              Falls Sie wiederholt Probleme bei der Anmeldung haben,
              kontaktieren Sie bitte die IT-Abteilung.
            </div>
            <div className='modal-footer'>
              <button
                type='button'
                className='btn btn-secondary'
                data-dismiss='modal'>
                Schließen
              </button>
            </div>
          </div>
        </div>
      </div> */}
      </StyledLogin>
    </>
  )
}

export default withRouter(Login)
