import React, { useEffect, useState, useRef, useContext } from 'react'
import { auth, firestore } from '../firebase'
import { CSSTransition } from 'react-transition-group'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faSignOutAlt,
  faGraduationCap,
  faChartBar,
  // faAward,
  // faAngleLeft,
} from '@fortawesome/free-solid-svg-icons'
import 'styled-components/macro'
import { Link } from 'react-router-dom'
import { AuthContext } from '../auth/Auth'
import { LanguageContext } from '../App'

const dropdown = `
  position: absolute;
  top: 58px;
  right: 0px;
  width: 200px;
  // transform: translateX(-45%);
  background-color: var(--bg);
  border: var(--border);
  border-radius: var(--border-radius);
  padding: 1rem;
  overflow: hidden;
  transition: height var(--speed) ease;
  z-index: 10;
  
  .menu {
    width: 100%;
  }
  
  .menu-item {
    height: 50px;
    display: flex;
    align-items: center;
    border-radius: var(--border-radius);
    transition: background var(--speed);
    padding: 0.5rem;
    color: var(--nav-text-color)
  }
  
  .menu-item .icon-button {
    margin-right: 0.5rem;
  }
  
  
  .menu-item .icon-button:hover {
    filter: none;
  }
  
  .menu-item:hover {
    background-color: #525357;
  }
  
  .icon-right {
    margin-left: auto;
  }

  .username {
    color: var(--nav-text-color);
    margin: 0 10px;
    margin-bottom: 1em;
  }

  hr {
    border-color: var(--nav-text-color);
    margin-left: 8px;
    margin-right: 8px;
  }

  .menu-primary-enter {
    position: absolute;
    transform: translateX(-110%);
  }
  .menu-primary-enter-active {
    transform: translateX(0%);
    transition: all var(--speed) ease;
  }
  .menu-primary-exit {
    position: absolute;
  }
  .menu-primary-exit-active {
    transform: translateX(-110%);
    transition: all var(--speed) ease;
  }


  .menu-secondary-enter {
    transform: translateX(110%);
  }
  .menu-secondary-enter-active {
    transform: translateX(0%);
    transition: all var(--speed) ease;
  }
  .menu-secondary-exit {

  }
  .menu-secondary-exit-active {
    transform: translateX(110%);
    transition: all var(--speed) ease;
  }


`

const DropdownMenu = ({ closeMenu }) => {
  const [activeMenu, setActiveMenu] = useState('main')
  const [menuHeight, setMenuHeight] = useState(null)
  const dropdownRef = useRef(null)
  const { currentUser: user } = useContext(AuthContext)
  const [language, setLanguage] = useContext(LanguageContext)

  useEffect(() => {
    setMenuHeight(dropdownRef.current.firstChild.offsetHeight)
  }, [])

  function calcHeight(el) {
    const height = el.offsetHeight
    setMenuHeight(height)
  }

  function DropdownItem(props) {
    const goToMenu = () => {
      props.goToMenu && setActiveMenu(props.goToMenu)
      closeMenu()
    }
    const onClick = props.onClick ? props.onClick : goToMenu
    return (
      <div className='menu-item' onClick={onClick}>
        <span className='icon-button'>{props.leftIcon}</span>
        {props.children}
        <span className='icon-right'>{props.rightIcon}</span>
      </div>
    )
  }

  return (
    <div
      css={dropdown}
      className='dropdown'
      style={{ height: menuHeight }}
      ref={dropdownRef}>
      <CSSTransition
        in={activeMenu === 'main'}
        timeout={500}
        classNames='menu-primary'
        unmountOnExit
        onEnter={calcHeight}>
        <div className='menu'>
          <div className='username'>
            {language === 'de' ? 'Willkommen' : 'Welcome'}, {user.firstname}
          </div>
          <hr />
          <Link to='/'>
            <DropdownItem leftIcon={<FontAwesomeIcon icon={faGraduationCap} />}>
              {language === 'de' ? 'Kursübersicht' : 'Course overview'}
            </DropdownItem>
          </Link>
          {user.isAdmin && (
            <Link to='/statistics'>
              <DropdownItem leftIcon={<FontAwesomeIcon icon={faChartBar} />}>
                {language === 'de'
                  ? 'Admin | Statistiken'
                  : 'Admin | Statistics'}
              </DropdownItem>
            </Link>
          )}
          {/* <Link to='/certificate'>
            <DropdownItem
              leftIcon={<FontAwesomeIcon icon={faAward} />}
              // rightIcon='B'
              // goToMenu='settings'
            >
              Zertifikat
            </DropdownItem>
          </Link> */}
          <DropdownItem
            leftIcon={language === 'de' ? '🇺🇸' : '🇩🇪'}
            onClick={async () => {
              const ref = await firestore
                .collection('usersPhase2')
                .doc(user.uid)
              const doc = await ref.get()
              const userLanguage = doc.data().language
              if (userLanguage === 'de') {
                ref.update({ language: 'en' })
                setLanguage('en')
              }
              if (userLanguage === 'en') {
                ref.update({ language: 'de' })
                setLanguage('de')
              }
            }}>
            {language === 'de' ? 'English version' : 'Deutsche Version'}
          </DropdownItem>
          <DropdownItem
            leftIcon={<FontAwesomeIcon icon={faSignOutAlt} />}
            onClick={() => {
              auth.signOut()
            }}
            // rightIcon='B'
            // goToMenu='animals'
          >
            {language === 'de' ? 'Ausloggen' : 'Log out'}
          </DropdownItem>
        </div>
      </CSSTransition>

      {/* <CSSTransition
        in={activeMenu === 'settings'}
        timeout={500}
        classNames='menu-secondary'
        unmountOnExit
        onEnter={calcHeight}>
        <div className='menu'>
          <DropdownItem
            goToMenu='main'
            leftIcon={<FontAwesomeIcon icon={faAngleLeft} />}>
            <h2>My Tutorial</h2>
          </DropdownItem>
          <DropdownItem leftIcon='C'>HTML</DropdownItem>
          <DropdownItem leftIcon='C'>CSS</DropdownItem>
          <DropdownItem leftIcon='C'>JavaScript</DropdownItem>
          <DropdownItem leftIcon='C'>Awesome!</DropdownItem>
        </div>
      </CSSTransition>

      <CSSTransition
        in={activeMenu === 'animals'}
        timeout={500}
        classNames='menu-secondary'
        unmountOnExit
        onEnter={calcHeight}>
        <div className='menu'>
          <DropdownItem
            goToMenu='main'
            leftIcon={<FontAwesomeIcon icon={faAngleLeft} />}>
            <h2>Animals</h2>
          </DropdownItem>
          <DropdownItem leftIcon='🦘'>Kangaroo</DropdownItem>
          <DropdownItem leftIcon='🐸'>Frog</DropdownItem>
          <DropdownItem leftIcon='🦋'>Horse?</DropdownItem>
          <DropdownItem leftIcon='🦔'>Hedgehog</DropdownItem>
        </div>
      </CSSTransition> */}
    </div>
  )
}

export default DropdownMenu
