import React, { useState } from 'react'
import './App.css'
import Navbar from './components/Navbar'
import NavItem from './components/NavItem'
import DropdownMenu from './components/DropdownMenu'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
// import PdfCertificate from './pages/Certificate'
// import SpoorSync from './services/SpoorSync'
import Login from './pages/Login'
import Auth from './auth/Auth'
import PrivateRoute from './routes/PrivateRoute'
import Home from './pages/Home'
// import DemoWBT from './pages/DemoWBT'
import Video from './pages/Video'
import Statistics from './pages/Statistics'
// import Logs from './pages/Logs'
// import Offline from './pages/Offline'

export const LanguageContext = React.createContext('de')

const App = () => {
  const [language, setLanguage] = useState('de')

  return (
    <>
      <LanguageContext.Provider value={[language, setLanguage]}>
        <Auth>
          {/* <SpoorSync /> */}
          <Router>
            <Navbar>
              <NavItem icon={<FontAwesomeIcon icon={faBars} />}>
                <DropdownMenu closeMenu></DropdownMenu>
              </NavItem>
            </Navbar>
            <Switch>
              {/* <PrivateRoute exact path='/' component={ScormWrapper} /> */}
              <PrivateRoute exact path='/' component={Home} />
              <PrivateRoute exact path='/statistics' component={Statistics} />
              <PrivateRoute exact path='/videos/:id' component={Video} />
              {/* <PrivateRoute exact path='/demo' component={DemoWBT} /> */}
              <Route exact path='/login' component={Login} />
              {/* <Route exact path='/logs' component={Logs} /> */}
              {/* <PrivateRoute
              exact
              path='/certificate'
              component={PdfCertificate}
            /> */}
              {/* <Route component={Offline} /> */}
            </Switch>
          </Router>
        </Auth>
      </LanguageContext.Provider>
    </>
  )
}

export default App
