import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/storage'
import 'firebase/analytics'
import 'firebase/functions'

const firebaseConfig = {
  apiKey: 'AIzaSyDfEVyDL34qrPZck-797vtH-dsXlidnVJU',
  authDomain: 'possehl-lms.firebaseapp.com',
  databaseURL: 'https://possehl-lms.firebaseio.com',
  projectId: 'possehl-lms',
  storageBucket: 'possehl-lms.appspot.com',
  messagingSenderId: '58601616940',
  appId: '1:58601616940:web:66f657421ed4caa5dc4389',
  measurementId: 'G-BL2N53886Q',
}

firebase.initializeApp(firebaseConfig)

export const firebaseInstance = firebase
export const auth = firebase.auth()
export const firestore = firebase.firestore()
export const storage = firebase.storage()
export const analytics = firebase.analytics()
export const functions = firebase.functions()

// if (window.location.hostname === 'localhost') {
//   console.log(
//     'testing locally -- hitting local functions and firestore emulators'
//   )
//   functions.useFunctionsEmulator(
//     'http://localhost:5001/possehl-lms/europe-west3/courses'
//   )
// }

export const generateUserDocument = async (user, context) => {
  if (!user) return
  const userRef = firestore.doc(`usersPhase2/${user.uid}`)
  const snapshot = await userRef.get()

  if (!snapshot.exists) {
    try {
      const email = auth.currentUser.email
      await userRef.set({
        firstLogin: new Date(),
        username: email,
        ...context,
      })
    } catch (error) {
      console.error('Error creating user document', error)
    }
  }

  return getUserDocument(user.uid)
}

const getUserDocument = async (uid) => {
  if (!uid) return null
  try {
    const userDocument = await firestore.doc(`usersPhase2/${uid}`).get()
    return {
      uid,
      ...userDocument.data(),
    }
  } catch (error) {
    console.error('Error fetching user', error)
  }
}
