import React, { useContext } from 'react'
import 'styled-components/macro'
import { AuthContext } from '../auth/Auth'
import { Link } from 'react-router-dom'

const navbar = `
    //IE 11
    height: 60px;
    background-color: #242526;
    border-bottom: 1px solid #474a4d;

    height: var(--nav-size);
    background-color: var(--bg);
    padding: 0 1rem;
    border-bottom: var(--border);
    display: flex;
    justify-content: space-between;

  .navbar-nav {
    max-width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
  }

  .logo {
    color: white;
    display: flex;
    align-items: center;

    img {
      // IE 11
      width: 45px;
      height: 45px;

      width: calc(var(--nav-size) * 0.75);
      height: calc(var(--nav-size) * 0.75);
      margin-right: 1rem;
    }

    .title {
      font-size: 1.2rem;
      font-weight: bold;
    }
  }
`

const Navbar = (props) => {
  const { currentUser: user } = useContext(AuthContext)

  return (
    <nav css={navbar} className='navbar'>
      <div className='logo'>
        <Link to='/'>
          <img src='/img/possehl-logo.png' alt='' />
        </Link>
        <Link to='/' className='title'>
          Possehl Digital Upskilling Program
        </Link>
      </div>
      <ul className='navbar-nav'>{user ? props.children : null}</ul>
    </nav>
  )
}

export default Navbar
