import React, { useState, useEffect, useContext } from 'react'
import styled from 'styled-components'
import WBTCard from '../components/WBTCard'
import { firestore } from '../firebase'
import { CircleLoader } from 'react-spinners'
import { AuthContext } from '../auth/Auth'
import { LanguageContext } from '../App'

const StyledHome = styled.div`
  /* background-color: #f0f0f0; */
  max-width: 1200px;
  margin: 0 auto;
  padding: 2em;
  /* height: calc(100vh - 61px - 4em); */

  .intro {
    font-size: 1.1em;
    line-height: 1.5em;
  }

  .card-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 250px));
    gap: 2em;
    width: 100%;
    padding-top: 1em;
    margin-bottom: 2em;
  }

  h2 {
    margin-top: 2em;
  }
`

const Home = () => {
  const [courses, setCourses] = useState([])
  const { currentUser: user } = useContext(AuthContext)

  const [language] = useContext(LanguageContext)

  useEffect(() => {
    const fetchCourses = async () => {
      const snapshot = await firestore
        .collection('coursesPhase2')
        // .where('hidden', '==', false)
        // .where('targetgroup', 'array-contains', user.targetgroup)
        .orderBy('id')
        .get()
      const courseList = []
      snapshot.docs.forEach((doc) => courseList.push(doc.data()))
      setCourses(courseList)
    }
    fetchCourses()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div>
      <StyledHome>
        {language === 'de' && (
          <>
            <p className='intro'>
              {user.sex === 'male'
                ? 'Lieber Possehlianer!'
                : 'Liebe Possehlianerin!'}
              <br />
              <br />
              Herzlich willkommen beim{' '}
              <strong>«Possehl Digital Upskilling Program»</strong> der Possehl
              Academy. Wir freuen uns auf das gemeinsame Programm und Ihr
              Feedback zu den Inhalten. Für etwaige Fragen stehen wir Ihnen
              gerne zur Verfügung.
            </p>
            <p className='intro'>
              <em>Markus Meier, Guido Horstmann und Karl Hoffmann.</em>
            </p>
            <h2>Kursübersicht</h2>
          </>
        )}
        {language === 'en' && (
          <>
            <p className='intro'>
              {user.sex === 'male'
                ? 'Dear Possehlianer!'
                : 'Dear Possehlianerin!'}
              <br />
              <br />
              Welcome to the{' '}
              <strong>«Possehl Digital Upskilling Program»</strong> of Possehl
              Academy. We look forward to the joint program and your feedback on
              the content. We will be happy to answer any questions you may
              have.
            </p>
            <p className='intro'>
              <em>Markus Meier, Guido Horstmann and Karl Hoffmann.</em>
            </p>
            <h2>Course overview</h2>
          </>
        )}
        <div className={`card-container ${courses === [] ? 'loading' : ''}`}>
          {courses === [] ? (
            <>
              <h3>Kurse werden geladen</h3>
              <CircleLoader color='#0070bb' />
            </>
          ) : (
            courses.map((course) => <WBTCard key={course.id} course={course} />)
          )}
        </div>
      </StyledHome>
    </div>
  )
}

export default Home
